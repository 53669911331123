import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import MasterLayout from '../components/MasterLayout/MasterLayout';
import PageTitle from './components/PageTitle';
import Sidebar from './components/Sidebar';

const PostContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`;

const Post = styled.div`
  @media (min-width: 768px) {
    flex: 3;
  }
`;

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
  location
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  return (
    <MasterLayout location={location}>
      <PostContainer>
        <Post>
          <PageTitle>{frontmatter.title}</PageTitle>

          <div className="post-content-wrapper">
            {!frontmatter.hideTitle && <h1>{frontmatter.title}</h1>}

            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </Post>

        <Sidebar />
      </PostContainer>
    </MasterLayout>
  );
};

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        hideTitle
      }
    }
  }
`;

export default Template;
