import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const StyledSidebar = styled.div`
  border-top: 1px solid #e5e5e5;
  padding-bottom: 30px;
  padding-top: 30px;

  @media (min-width: 768px) {
    border-left: 1px solid #e5e5e5;
    border-top: 0;
    flex: 1;
    margin-left: 40px;
    padding-left: 40px;
  }
`;

const SidebarLink = ({ children, to }) => (
  <li>
    <Link to={to}>{children}</Link>
  </li>
);

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

const Sidebar = () => (
  <StyledSidebar>
    <div>
      <h4>Recent articles</h4>

      <ul>
        <SidebarLink to="/articles/fmqs">FMQs</SidebarLink>

        <SidebarLink to="/articles/everyones-flag">
          {`Everyone's flag`}
        </SidebarLink>

        <SidebarLink to="/articles/the-snp-has-lost-its-moral-authority/">
          The SNP has lost its moral authority
        </SidebarLink>
      </ul>
    </div>

    <div>
      <h4>The Think Again articles</h4>

      <ul>
        <SidebarLink to="/three-articles-published-in-the-press-and-online/">
          Three articles published in the press and online
        </SidebarLink>

        <SidebarLink to="/when-remaining-silent-is-not-an-option/">
          When remaining silent is not an option…
        </SidebarLink>

        <SidebarLink to="/why-we-must-keep-campaigning/">
          Why we must keep campaigning
        </SidebarLink>

        <SidebarLink to="/21-out-of-a-hundred-the-many-ways-the-snp-have-tried-to-mislead-us/">
          21 out of a hundred – the many ways the SNP have tried to mislead us
        </SidebarLink>

        <SidebarLink to="/accidental-witness/">Accidental Witness</SidebarLink>

        <SidebarLink to="/differences/">Differences</SidebarLink>

        <SidebarLink to="/long-campaign/">The Long Campaign</SidebarLink>

        <SidebarLink to="/scottish-referendum-result-know-think-know/">
          The Scottish Referendum Result: So what do we know, and what do we
          think we know?
        </SidebarLink>

        <SidebarLink to="/no-thanks-for-a-generation/">
          No Thanks for a Generation
        </SidebarLink>

        <SidebarLink to="/indyref-aftermath/">#indyref aftermath</SidebarLink>

        <SidebarLink to="/the-psyche-of-the-mob/">
          The Psyche of the Mob
        </SidebarLink>

        <SidebarLink to="/19th-september-postscript/">
          19th September postscript
        </SidebarLink>

        <SidebarLink to="/it-is-not-over-yet/">
          “…it is not over yet”
        </SidebarLink>

        <SidebarLink to="/referendum-reflections/">
          Referendum Reflections
        </SidebarLink>

        <SidebarLink to="/offer-of-help/">Offer of help</SidebarLink>

        <SidebarLink to="/30-second-pitch/">30 second pitch</SidebarLink>

        <SidebarLink to="/the-2-elephants-in-the-room/">
          The 2 Elephants in the Room
        </SidebarLink>

        <SidebarLink to="/scottish-independence-referendum-debate-exemplar-unfolding-tragedy/">
          The Scottish Independence Referendum debate – ‘exemplar’ or an
          unfolding tragedy?
        </SidebarLink>
      </ul>
    </div>

    <div>
      <h4>‘If Scotland’ talk</h4>

      <ul>
        <SidebarLink to="/talk-given-keith-howell-at-the-if-scotland-conference-university-of-stirling/">
          Talk given by Keith Howell at the ‘If Scotland’ conference at
          University of Stirling 24th August’14
        </SidebarLink>
      </ul>
    </div>

    <div>
      <h4>The Think Again videos</h4>

      <ul>
        <SidebarLink to="/the-scottish-independence-referendum-think-again-adverts/">
          5 reasons I will vote ‘No’ (30th May ’14 – 2 min. version)
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum-think-again-adverts/">
          5 reasons I will vote ‘No’ (30th May ’14 – 6 min. version)
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum-think-again-adverts/">
          Think Again (6th March ’14 – 7 mins.)
        </SidebarLink>
      </ul>
    </div>

    <div>
      <h4>The Think Again adverts</h4>

      <ul>
        <SidebarLink to="/the-scottish-independence-referendum/think-again/">
          Think Again
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/the-white-paper/">
          The White Paper
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/a-different-view/">
          A Different View
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/the-first-minister-has-no-clothes/">
          The First Minister Has No Clothes
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/the-economics-of-independence/">
          The Economics of Independence
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/10-things-they-forgot-to-tell-you/">
          10 Things They Forgot to Tell You
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/good-people/">
          Good People
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/how-to-speak/">
          How to Speak…
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/im-sorry-to-spoil-the-party/">
          I’m Sorry to Spoil the Party
        </SidebarLink>

        <SidebarLink to="/the-scottish-independence-referendum/forever/">
          Forever
        </SidebarLink>
      </ul>
    </div>
  </StyledSidebar>
);

export default Sidebar;
