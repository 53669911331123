import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const PageTitle = ({ children }) =>
  children && (
    <Helmet>
      <title>{`${children} - Nupateer`}</title>
    </Helmet>
  );

PageTitle.propTypes = {
  children: PropTypes.node
};

export default PageTitle;
